import { createStore } from 'vuex'
import { Cookie } from '@/utils/request';

function getOpenNav() {
    let isNavOpen = localStorage.getItem('isNavOpen');
    if (['true', 'false'].indexOf(isNavOpen) == -1) {
        isNavOpen = true
        localStorage.setItem('isNavOpen', true,);
    } else {
        isNavOpen = isNavOpen == 'true';
    }
    return isNavOpen;
}

function getLng() {
    let lng = localStorage.getItem('acceptLanguage');
    if (['en', 'ru',].indexOf(lng) == -1) lng = 'ru';
    localStorage.setItem('acceptLanguage', lng,); 
    return lng;
}

export default createStore({
    state: {
        user: {},
        factorUser: {
            factor_company: {
                logo: null,
                name_short: {
                    en: null,
                },
            },
        },
        isTokenChecked: false,
        isNavOpen: getOpenNav(),
        modalData: {
            showModal: false,
            messages: [{
                'title': 'title',
                'message': 'message',
            }],
            title: 'title',
            isError: false,
        },
        acceptLanguage: getLng(),
        activeNavMenu: localStorage.getItem('activeNavMenu') == 'true',
        alertData: {
            isOpen: false, 
            message: '', 
            isError: false,
            interVal: null,
        }
    },
    mutations: {
        tokenChecked(state) {
            state.isTokenChecked = true;
        },
        closeAlert(state) {
            if(state.alertData.interVal) {
                clearInterval(state.alertData.interVal);
                state.alertData.interVal = null;
            }
            state.alertData.interVal = setTimeout(()=> {
                state.alertData.isOpen = false
            }, 2500)
        },
        toggleisNavOpen(state) {
            state.isNavOpen = !state.isNavOpen;
            localStorage.setItem('isNavOpen', state.isNavOpen);
        },
        setUserData(state, data,) {
            state.user = data;
        },
        setFactorUserData(state, data,) {
            state.factorUser = data;
        },
        updateFactorLogoImage(state, obj,) {
            state.factorUser.factor_company = {
                ...state.factorUser.factor_company,
                ...obj,
            };
        },
        openAlert(state, { message, isError}) {
            state.alertData = {
                message: message, 
                isError: isError, 
                isOpen: true
            };
        },
        toggleActiveNav(state) {
            state.activeNavMenu = !state.activeNavMenu;
            localStorage.setItem('activeNavMenu', state.activeNavMenu);
        },
        createUser(state, obj) {
            Cookie.set('user', JSON.stringify(obj), 30,);
            state.user = obj;
        },
        changeLanguage(state, lng) {
            if(['en', 'ru',].indexOf(lng) == -1) lng = 'ru';
            state.acceptLanguage = lng;
            localStorage.setItem('acceptLanguage', lng);
        }, 
        updateUser(state, obj) {
            const data = {
                ...state.user, 
                ...obj,
            }
            Cookie.set('user', JSON.stringify(data), 30,);
            state.user = data;
        },
        modalShow(state, { messages, title, isError }) {
            state.modalData = {
                showModal: true,
                messages: messages,
                title: title,
                isError: isError,
            }
        },
        modalClose(state) {
            state.modalData = {
                showModal: false,
                messages: [],
                title: '',
                isError: false,
            }
        }
    },
    actions: {
        tokenChecked(store) {
            store.commit('tokenChecked',);
        },
        openModal(store, { messages, title, isError }) {
            store.commit('modalShow', { messages, title, isError });
        },
        toggleAlert(store, {message, isError}) {
            store.commit('openAlert', {message, isError});
            store.commit('closeAlert', {message, isError});
        },
        openMobileNav(store) {
            store.commit('openMobileNav');
        },
        toggleActiveNav(store) {
            store.commit('toggleActiveNav');
        },
        updateUser(store, obj) {
            store.commit('updateUser', obj);
        },
        changeLanguage(store, lng) {
            store.commit('changeLanguage', lng);
        },
        updateFactorLogoImage(store, obj,) {
            store.commit('changeLanguage', obj);
        },
    }
})