/* eslint-disable no-undef */
<template>
  <div class="question-item margin-bt-20 pointer">
    <div
        class="relative overflow-hidden rounded-lg border bg-white select-none hover:shadow hover:shadow-teal-200 p-2">
        <div class="flex flex-row justify-start items-center rounded-md p-3">
          <div
            v-if="answData.icon"
            :class="[
              !answData.icon ? 'n-border': '',
            ]"
            class="img margin-r-20">
            <div 
              :style="getIcon"
              class="bg-img"></div>
          </div>
          <div class="space-y-2">
              <!-- {{ answData }} <br> -->
              <p
                v-if="answData.title"
                class="text-sm f-16 font-bold text-muted-foreground margin-bt-10">
                {{ answData.title }} 
              </p>
              <div class="">
                <div v-if="answData.measure != '%'"  >
                  <span class="font-bold f-16">
                    {{ beautifyInteger(answData.result) }}</span> <span>{{ answData.measure }}
                  </span>
                </div>
                <div v-else>
                  <span class="font-bold f-16">
                    {{ beautifyInteger(answData.result) }}</span>
                    <span>{{ answData.measure }}</span>
                    <!-- <span v-if="answData.result"> ({{ beautifyInteger(answData.result * 10) }})</span> -->
                </div>
              </div>
          </div>
        </div>        
    </div>
  </div>
</template>

<script>
import { beautifyInteger } from '@/utils/base';

export default { 
  data() {
    return {};
  },
  computed: {
    getIcon() {
      if (this.$props.answData && this.$props.answData.icon) {
        const iconUrl = this.$props.answData.icon;
        return { backgroundImage: `url(${iconUrl})` };
      }
      return {};
    }
  },
  methods: {
    beautifyInteger,
  },
  props: {
    answData: {
      default: {
        icon: null,
      }
    },
  },
}

</script>
/* eslint-disable no-undef */
