import { API_URL } from './uri.dev'

const API_BASE = {
  city: `${API_URL}/city`,
  survey: `${API_URL}/survey`,
  results: `${API_URL}/results`,
  graphic: `${API_URL}/graphic`,
  surveyAll: `${API_URL}/survey-all`,
  graphicAll: `${API_URL}/graphic-all`,
}

function getLng() {
  let lng = localStorage.getItem('acceptLanguage');
  if (['en', 'ru',].indexOf(lng) == -1) lng = 'ru';
  return lng;
}

const HEADERS = () => {
  return {
    'Accept-Language': getLng(), 
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  };
}

const HEADERS_TOKEN = () => {
  return {
    'Accept-Language': getLng(), 
    'Authorization': 'Token ' + localStorage.getItem('user_token'),
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  };
}

const HEADERS_TOKEN_JSON = () => {
  return {
    'Accept-Language': getLng(), 
    'Authorization': 'Token ' + localStorage.getItem('user_token'),
    'Accept': 'application/json',
  };
}

const HEADERS_TOKEN_JSON_FILE = () => {
  return {
    'Accept-Language': getLng(), 
    'Authorization': 'Token ' + localStorage.getItem('user_token'),
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data',
  };
}

export { 
  API_BASE, HEADERS,
  HEADERS_TOKEN, HEADERS_TOKEN_JSON,
  HEADERS_TOKEN_JSON_FILE,
}
