const colorMap = {
  'g-kostanai': '#FF5733',
  'arkalyk-ga': '#33FF57',
  'lisakovsk-ga': '#3357FF',
  'rudny-ga': '#FF33A5',
  'altynsarinsky': '#33FFA5',
  'amangeldinsky': '#A533FF',
  'auliekolsky': '#FFA533',
  'denisovsky': '#5733FF',
  'dzhangeldinsky': '#A5FF33',
  'zhitikarinsky': '#FF3357',
  'kamystinsky': '#33A5FF',
  'karabalyksky': '#FF5733',
  'karasusky': '#33FF57',
  'kostanaysky': '#3357FF',
  'mendykarynsky': '#FF33A5',
  'naurzumsky': '#33FFA5',
  'sarykolsky': '#A533FF',
  'beimbeta-mailina': '#FFA533',
  'uzunkolsky': '#5733FF',
  'fedorovsky': '#A5FF33',
  'kostanayskaya-oblast': '#FF3357'
};

function hexToRgba(hex, opacity) {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

const colorMap2 = {};
for (const [key, value] of Object.entries(colorMap)) {
  colorMap2[key] = hexToRgba(value, 0.75);
}

export {
  colorMap, colorMap2
}