<template>
  <div style="graphic-wrapper">
    <div class="flex flex-row justify-between chart-container">
      <div class="">
        <div class="text-gray-900 f-15 font-bold margin-bt-5">Район проживания</div>
        <div
          class="text-gray-900 f-14 pointer"
          :class="[
            activeCities.indexOf(el.slug) != -1 || (
              activeCities.length == 0 && ind == 0
            ) ? 'font-bold' : '',
          ]"
          @click="() => {
            toggleCities(el.slug);
          }"
          v-for="(el, ind) in filterCities" :key="ind">
          <div>
          <input
            :checked="
              activeCities.indexOf(el.slug) != -1 || (
                activeCities.length == 0 && ind == 0
              )
            "
            type="checkbox" 
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
          <label 
            class="ms-2 text-sm text-gray-900 dark:text-gray-300">{{ el.name }}</label>
          </div>
        </div>
      </div>

      <div class="graphic-doughnut" style="">
        <Doughnut
          :data="chartData" 
          :options="chartOptions"
        />
      </div>

    </div>
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs';
import { chartBackgroundColor } from '@/utils/const.js';

import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ChartDataLabels, ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  components: {
    Doughnut,
  },  
  computed: {
    filterCities() {
      const uniqueCitySlugs = [...new Set(this.gData.map(item => item.city_slug))];
      const cities = this.$props.cities.filter(
        (el) => uniqueCitySlugs.indexOf(el.slug) != -1
      );
      return cities;
    },
    currentActiveCities() {
      let activeCities = null;
      if(this.activeCities.length == 0) {
        activeCities = [this.filterCities[0].slug];
      } else {
        activeCities = this.activeCities;
      }
      return activeCities; 
    },
    chartLabels() {
      const activeCities = this.currentActiveCities;
      const uniqueHeaders = [
          ...new Set(
            this.gData
            .filter(el => activeCities.indexOf(el.city_slug) == -1)
            .map(item => item.title)
        )
      ];
      return uniqueHeaders;
    },
    chartValues() {
      const activeCities = this.currentActiveCities;
      const filteredData = this.gData.filter(item => activeCities.includes(item.city_slug));

      // Group the data by city slug
      const groupedData = {};
      filteredData.forEach(item => {
          if (!groupedData[item.city_slug]) {
              groupedData[item.city_slug] = [];
          }
          groupedData[item.city_slug].push(item.result || 0);
      });

      // Convert grouped data to an array of results
      const resultArray = [];
      activeCities.forEach(citySlug => {
        if (groupedData[citySlug]) {
          resultArray.push(groupedData[citySlug]);
        }
      });

      return resultArray;
    },
  },
  data() {
    return {
      activeCities: [],
      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: chartBackgroundColor,
            data: [300, 50, 100,]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: ''
          },
          datalabels: {
            color: '#fff',
            anchor: 'end',
            align: 'start',
            font: {
              weight: 'bold',
              size: 18
            },
            formatter: (value) => `${value}`,
          }
        }
      }, 
    };
  },
  watch: {
    cities() {
      this.setchartLabels();
    },
  },
  mounted() {
    this.activeCities = [];
    this.activeCities = [this.filterCities[0].slug];
    this.setchartLabels();
  }, 
  methods: {
    toggleCities(value) {
      const valIndex = this.activeCities.indexOf(value);
      if(valIndex == -1) {
        this.activeCities.push(value);
      } else {
        this.activeCities.splice(valIndex, 1);
      }
      this.setchartLabels();
    },
    combineAndAverage(...arrays) {
      if (arrays.length === 0) return [];
      
      const arrayLength = arrays[0].length;
      
      // Initialize an array to store the sum of elements
      const sumArray = new Array(arrayLength).fill(0);
      
      // Sum corresponding elements from all arrays
      arrays.forEach(arr => {
        arr.forEach((value, index) => {
          sumArray[index] += value;
        });
      });

      // Calculate the average values
      const averageArray = sumArray.map(value => {
        const avg = Math.min(value / arrays.length, 100);
        return Math.floor(avg * 100) / 100;
      });
      return averageArray;
    },
    setchartLabels() {
      const res = this.chartValues;
      const chartData = {
        labels: this.chartLabels,
        datasets: [
          {
            label: '',
            backgroundColor: chartBackgroundColor,
            data: this.combineAndAverage(...res),
          }
        ]
      };
      this.chartData = {...chartData};
      const datalabels = {
        color: '#fff',
        anchor: 'end',
        align: 'start',
        font: {
          weight: 'bold',
          size: this.chartLabels.length < 8 ? 18 : 15, 
        },
        formatter: (value) => `${value}`,
      };
      this.chartOptions.plugins.datalabels = {...datalabels};
    },
  },
  props: {
    gData: {
      default: [],
    },
    cities: {
      default: [],
    },
  },
};
</script>