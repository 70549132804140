const chartBackgroundColor = [
  '#FF6384',
  '#36A2EB',
  '#FFCE56',
  '#4BC0C0',
  '#9966FF',
  '#FF9F40',
  '#FFCD56',
  '#4BC0C0',
  '#36A2EB',
  '#FF6384',
  '#DCDCDC',
  '#4B5000',
  '#C9CBFF',
  '#3EDBF0',
  '#FF6492',
  '#8E44AD',
  '#2980B9',
  '#E67E22',
  '#16A085',
  '#2C3E50',
];

export {
  chartBackgroundColor,
}
