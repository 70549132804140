<template>
  <div 
    ref="scrollableDiv"
    class="questions-data-modal">
    {{ currentQuarter }} <br>
    <div 
      ref="targetDiv"
      :style="{ height: targetDivHeight + 'px' }"
      class="bg-in rounded-xl shadow-xl"></div>
    <div class="question-wrapper p-3 px-4 ">
      <div
        @click="
          () => {
            closeModal();
          }
        "
        class="cross pointer"
      >
        <img src="/assets/images/icons/close.png" alt="" />
      </div>

      <div 
        v-if="isLoading"
        class="center-block">
        <img src="/assets/images/loading/loading_1.svg" alt="">
      </div>
      <div
        v-else 
        class="margin-t-45">
        <div class="q-center-title text-center f-27 font-bold margin-bt-25">
          {{ survey.title }}
        </div>
        
        <div class="q-block">
          <div 
            class="q-item"
            v-for="(el, ind) in qData" :key="ind">
            <div 
              class="pointer q-center-title f-19 font-semibold margin-bt-10 pr-3">
              {{ el.title }}
              <img 
                :class="[
                  activeDoughnut == ind ? 'active': '',
                ]"
                @click.prevent="() => {
                  toggleActiveDoghnut(ind, el.title_id,);
                }"
                style="width: 25px; height: 25px;"
                src="/assets/images/icons/graph.png" alt="">
            </div>
            <div class="answer-list">
              <SmItem 
                v-for="(inEl, inInd) in el.data.filter((el) => el.result)"  :key="inInd"
                :answData="inEl"
              />
            <div 
              v-if="activeDoughnut == ind" 
              class="doughnut-wrapper margin-bt-35 rounded-xl shadow-xl bg-white">
              <Doughnut :data="chartData" :options="chartOptions"/>
            </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import { API_BASE } from '@/const/uri';
import SmItem from '@/components/Question/SmItem.vue';

import { Doughnut } from 'vue-chartjs';
import { chartBackgroundColor } from '@/utils/const.js';

import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  components: {
    SmItem, Doughnut,
  },
  data() {
    return {
      targetDivHeight: 0,
      activeDoughnut: null,
      chartData: {
        labels: ['Red', 'Blue',],
        datasets: [
          {
            label: '',
            backgroundColor: chartBackgroundColor,
            data: [300, 50, ]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: ''
          },
          datalabels: {
            color: '#fff',
            anchor: 'end',
            align: 'start',
            font: {
              weight: 'bold',
              size: 16,
            },
            formatter: (value) => `${value}`,
          },
        }
      }, 
      survey: {},
      qData: [],
      isLoading: true,
      activeCitySlug: null,
    };
  },
  mounted() {
    this.loadData();
    this.setTargetDivHeight();
    window.addEventListener('resize', this.setTargetDivHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setTargetDivHeight);
  },
  watch: {
    currentQuarter() {
      this.loadData();
      this.setTargetDivHeight();
      window.addEventListener('resize', this.setTargetDivHeight);
    },
    activeCity() {
      this.isLoading = true;
      this.loadData();
    },
    qData: {
      handler() {
        this.setTargetDivHeight();
      },
      deep: true
    },
  },
  methods: {
    setTargetDivHeight() {
      this.$nextTick(() => {
        if (this.$refs.scrollableDiv) {
          this.targetDivHeight = this.$refs.scrollableDiv.scrollHeight;
        }
      });
    },
    toggleActiveDoghnut(ind, titleId) {
      if(this.activeDoughnut == ind) {
        this.activeDoughnut = null;
        return;
      }
      this.doghnutGetData(ind, titleId);
    },
    async doghnutGetData(ind, titleId,) {
      await axios({
        method: "GET",
        url: API_BASE.graphic,
        params: {
          quarter: this.$props.currentQuarter,
          survey_id: this.$props.surveyId,
          title_id: titleId,
          city_slug: this.$props.activeCity,
        },
      })
        .then((e) => {
          const data = e.data;
          const headers = [];
          const dData = [];
          data.forEach((el, ) => {
            headers.push(el.title || el.header_name || el.group_title);
            dData.push(el.result);
          });
          this.chartData.labels = headers;
          this.chartData.datasets[0].data = dData;
          this.activeDoughnut = ind;
        })
        .catch((e) => {
          this.isLoading = true;
          console.log(e);
        });
    },
    async loadData() {
      const obj = {
        quarter: this.$props.currentQuarter,
        city_slug: this.$props.activeCity,
        survey_id: this.$props.surveyId,
      };
      if(this.$props.isNotSort) {
        obj['is_not_sort'] = this.$props.isNotSort;
      }
      await axios({
        method: "GET",
        url: API_BASE.results,
        params: obj,
      })
        .then((e) => {
          this.survey = e.data.survey;
          this.qData = e.data.results;
          this.isLoading = false;
          this.activeCitySlug = e.data.city_slug;
          if(this.activeCitySlug != this.$props.activeCity) {
            this.$parent.updateCurrentCity(this.activeCitySlug);
          }
          const citiesSlug = e.data.cities;
          this.$parent.filterCities(citiesSlug);
        })
        .catch((e) => {
          this.isLoading = true;
          console.log(e);
        });
    },
  }, 
  props: {
    currentQuarter: {
      default: 2,
    },
    closeModal: {
      default: () => {},
    },
    activeCity: {
      default: null,
    },
    isNotSort: {
      default: false,
    },
    surveyId: {
      default: null,
    },
  },
};
</script>