<template>
  <div class="dashboard-block">
    <div 
      @click="toggleMobileMenu"
      class="burger-menu-m">
      <img src="/assets/images/icons/menu.png" alt="">
    </div>
    <aside
      :style="{
        transform: showMobileMenu ? 'none' : 'translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))',
      }"
      class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar"
    >
      <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
        <ul class="space-y-2 font-medium">
          <li>
            <a
              @click.prevent="closeModal"
              class="pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <img 
                style="width: 15px; height: 15px;"
                src="/assets/images/icons/close.png" alt="" />
            </a>
          </li>

          <li v-for="(el, ind) in surveys" :key="ind"> 
            <a
              @click.prevent="() => {
                getDasboard(el.id);
              }"
              class="pointer flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <span 
                :class="[
                  activeSurvey == el.id ? 'font-bold' 
                    : ''
                ]"
                class="ms-3">{{ el.title }}</span>
            </a>
          </li>

        </ul>
      </div>
    </aside>

    <div class="p-4 sm:ml-64 margin-right-10">
      <div
        class="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700"
      >
        <div 
          :style="{
            height: '100vh',
            maxHeight: '100vh',
            overflowY: 'scroll',
          }"
          class="grid grid-cols-2 gap-4 graphic-rows">
          <div
            v-for="(el, ind) in groupTitleData" :key="ind"
            class="flex flex-col px-2 py-3 rounded bg-gray-50 dark:bg-gray-800"
          >
            <div class="text-gray-900 f-18 font-bold margin-bt-20">{{ el.group_title }}</div>
            <Graphic 
              :currentQuarter="currentQuarter"
              :key="ind + el.id"
              :gData="el.data"
              :cities="cities.filter((el) => el.slug != 'kostanayskaya-oblast')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_BASE } from "@/const/uri";
import axios from "axios";
import Graphic from "@/components/Dashboard/Graphic.vue";

export default {
  components: {
    Graphic,
  },
  data() {
    return {
      activeSurvey: null,
      surveys: [],
      titleData: [],
      showMobileMenu: !(window.innerWidth < 950),
    };
  },
  computed: {
    groupTitleData() {
      const groupedData = this.titleData.reduce((acc, item) => {
          const { title_id, group_title, result, measure, city_name, city_slug, title } = item;
          if (!acc[title_id]) {
              acc[title_id] = {
                  group_title: group_title,
                  title_id: title_id,
                  data: []
              };
          }
          acc[title_id].data.push({ result, measure, city_name, city_slug, title });
          return acc;
      }, {});
      return Object.values(groupedData);
    },
  },
  mounted() {
    this.surveyAll(); 
  },
  watch: {
    currentQuarter() {
      this.surveyAll();
    },
  },
  methods: {
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    async getDasboard(surveyId) {
      console.log('surveyId:', surveyId,);
      await axios({
        method: "GET",
        url: API_BASE.graphicAll,
        params: {
          quarter: this.$props.currentQuarter,
          survey_id: surveyId,
        },
      })
        .then((e) => {
          this.titleData = e.data;
          this.activeSurvey = surveyId;
          this.showMobileMenu = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async surveyAll() {
      await axios({
        method: "GET",
        params: {
          quarter: this.$props.currentQuarter,
        },
        url: API_BASE.surveyAll,
      })
        .then((e) => {
          this.surveys = e.data;
          this.getDasboard(this.surveys[1].id);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  props: {
    closeModal: {
      default: () => {},
    },
    currentQuarter: {
      default: 2,
    },
    cities: {
      default: [],
    },
  },
};
</script>