import App from "@/App.vue";
import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import i18n from "./i18n" 

import "vue-search-select/dist/VueSearchSelect.css";
import '@vuepic/vue-datepicker/dist/main.css';
import '@/assets/styles/css/index.css';

import Admin from "@/layouts/Admin.vue";
import Index from "@/views/Index.vue";

// routes

const routes = [
  {
    path: "",
    redirect: "",
    component: Admin,
    name: "EmptyAdmin",
    children: 
    [
      {
        path: "",
        component: Index,
        name: 'Index',
      },
    ]
  },
  { path: "/:pathMatch(.*)*", redirect: "/client/company" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
}); 

// to, from, next
router.beforeEach(() => {
  // const token = localStorage.getItem('user_token')
  // if (token || to.name === 'Login' || to.name == 'AcivateUser') {
  //   next()
  // } else {
  //   next({name: 'Login'})
  // }
}); 

import user from '@/store/user.js';

createApp(App)
  .use(user)
  .use(i18n)
  .provide('$router', router)
  .use(router)
  .mount("#app");
