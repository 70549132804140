/* eslint-disable no-undef */
<template>
  <div>

    <QuestionList
      class=""
      :currentQuarter="currentQuarter"
      :surveyId="surveyId"
      :activeCity="activeCity"
      :closeModal="toggleQuestionData"
      :isNotSort="isNotSort"
      v-if="showQuestionData"
    />

    <DashboardIndex
      v-if="showDashboard"
      :closeModal="toggleDashboard"
      :cities="allCities"
      :currentQuarter="currentQuarter"
    />

    <Header :changeQuarter="changeQuarter" />
    
    <div class="nav-icons flex flex-row margin-r-20">
      <div 
        @click="() => {openSyrveyData();}"
        class="item margin-l-15 pointer">
        <img
          src="/assets/images/icons/people-together.png" alt="">
      </div>

      <div 
        class="item margin-l-15 pointer relative">
        <img
          @click="toggleQuestionNav"
          src="/assets/images/icons/question-and-answer.png" alt="">
        
        <div 
          v-if="showNavQuestionNav"
          class="hover-elems shadom-sm rounded-lg text-left py-4 px-6 divide-y divide-slate-200">
          <img
            @click.stop="() => {
              toggleQuestionNav();
            }"
            class="close"
            src="/assets/images/icons/close.png" alt="" />
          <div
            v-for="(el, ind) in surveyBase" :key="ind"
            @click="() => {
              toggleQuestionNav();
              openSyrveyData(el.id);
            }"
            class="marbin-bt-15 f-20 py-2"
          >
            {{ ind+1 }}. {{ el.title }}
          </div>
        </div>

      </div>

      <div 
        class="item margin-l-15 pointer relative">
        <img
          @click="toggleQuestionNav2"
          src="/assets/images/icons/border.png" alt="">
        
        <div 
          v-if="showNavQuestionNav2"
          class="hover-elems shadom-sm rounded-lg text-left py-4 px-6 divide-y divide-slate-200">
          <img
            @click.stop="() => {
              toggleQuestionNav();
            }"
            class="close"
            src="/assets/images/icons/close.png" alt="" />
          <div
            v-for="(el, ind) in surveyFrontier" :key="ind"
            @click="() => {
              toggleQuestionNav2();
              openSyrveyData(el.id, true,);
            }"
            class="marbin-bt-15 f-20 py-2"
          >
            {{ ind+1 }}. {{ el.title }}
          </div>
        </div>
      </div>

      <div 
        class="item margin-l-15 pointer relative">
        <img
          @click="toggleDashboard"
          src="/assets/images/icons/dashboard.png" alt="">        
      </div>

    </div>

    <div
      ref="map"
      id="map-container"
      class="map-container"></div>

    <div class="cities-list">
      <div 
        v-for="(el, ind) in cities" :key="ind"
        @click="() => {
          activeCity = el.slug;
        }"
        :class="[
          el.slug == activeCity ? 'font-bold active': 'null',          
        ]"
        :style="{
          backgroundColor: el.slug == activeCity 
            ? colorMap2[activeCity]: 'rgba(0,0,0,0.5)',          
        }"
        class="item f-18 pointer ">{{ el.name }}</div>
    </div>

  </div>
</template>

<script>
import { API_BASE } from '@/const/uri';
import axios from 'axios';
import Header from '@/components/Header.vue';
import { colorMap, colorMap2 } from '@/const/colors';
import QuestionList from '@/components/Question/List.vue';
import DashboardIndex from '@/components/Dashboard/Index.vue';

/* eslint-disable no-undef */
export default {
  components: {
    QuestionList,
    DashboardIndex,
    Header,
  },
  data() {
    return {
      currentQuarter: null,
      mapboxAccessToken:  'pk.eyJ1IjoiZG1pdGlyeWthdWtpbiIsImEiOiJjbHhqN280ZGMxa21hMmtzYnY3eHJwMnlrIn0.cWDzrflb4W_vZ5Ovd9qFtQ',
      map: null,
      isNotSort: false, 
      kostanaySlug: 'kostanayskaya-oblast',
      activeCity: null,
      cities: [],
      allCities: [],
      layers: [],
      qData: null,
      surveys: [],
    
      showQuestionData: false,
      showNavQuestionNav: false,
      showNavQuestionNav2: false,
      colorMap, colorMap2,

      showDashboard: false,
    };
  },
  computed: {
    surveyBase() {
      return this.surveys.filter((el) => !el.is_frontier);
    },
    surveyFrontier() {
      return this.surveys.filter((el) => el.is_frontier);
    },
  },
  mounted() {
    this.currentQuarter = localStorage.getItem('quarter') || 2;
    this.map = L.map('map-container').setView([63.631887, 53.214936], 10);
    L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}&lang=ru`, {
          id: 'mapbox/streets-v12',
          accessToken: this.mapboxAccessToken,
          tileSize: 512,
          zoomOffset: -1,
          maxZoom: 16,
          minZoom: 6,
      }).addTo(this.map);
      this.map.setView([52.214936, 63.631887], 6.5);
      this.getCities();
      this.getSurveys();
  },
  watch: {
    activeCity(to, old,) {
      if(to == old) return;
      const cityData = this.cities.filter((el) => el.slug == to); 
      this.removeLayerAll();
      if(cityData.length > 0) {
        if(to != this.kostanaySlug) {
          this.addLayer(this.allCities[0], false,);
        }
        this.addLayer(cityData[0], true,);
      }
    },
  },
  methods: {
    changeQuarter(quarter) {
      this.currentQuarter = quarter;
      this.getSurveys();
    },
    toggleDashboard() {
      this.showDashboard = !this.showDashboard;
    },
    updateCurrentCity(city) {
      this.activeCity = city;
    },
    filterCities(slugs) {
      this.cities = this.allCities.filter( (el) => slugs.indexOf(el.slug) != -1);
    },
    toggleQuestionNav() {
      this.showNavQuestionNav = !this.showNavQuestionNav;
      this.showNavQuestionNav2 = false;
    },
    toggleQuestionNav2() {
      this.showNavQuestionNav2 = !this.showNavQuestionNav2;
      this.showNavQuestionNav = false;
    },
    openSyrveyData(surveyId, isNotSort) {
      if(isNotSort) {
        this.isNotSort = true;
      } else {
        this.isNotSort = false;
      }
      if(!surveyId) {
        this.surveyId = 1;
      } else {
        this.surveyId = surveyId;
      }
      this.toggleQuestionData();
    },
    toggleQuestionData() {
      this.showQuestionData = !this.showQuestionData;
    },
    removeLayerAll() {
      this.layers.forEach((el) => {
        this.removeLayer(el);
      });
    }, 
    centerMapByFeature(bounds) {
      this.map.fitBounds(bounds);
    },
    removeLayer(layer) {
      if (this.map.hasLayer(layer)) {
        this.map.removeLayer(layer);
      }
    },
    addLayer(city, bound = false,) {
      var geoJsonLayer = L.geoJson(JSON.parse(city.feature), {
        style: function () {
          return {
            color: colorMap[city.slug] || '#000000', // Fallback to black if slug is not in colorMap
            weight: 2,
            opacity: 1,
          };
        }
      });

      // Calculate the center of the feature
      var bounds = geoJsonLayer.getBounds();
      var center = bounds.getCenter();

      if(city.slug != this.kostanaySlug) {
        geoJsonLayer.bindTooltip(city.name, {
            permanent: true,
            direction: 'center',
            className: 'city-label'
        }).openTooltip(center, {
            opacity: 0
        });
      }
      this.layers.push(geoJsonLayer);
      geoJsonLayer.addTo(this.map);
      if(bound) {
        geoJsonLayer.getBounds();
        this.map.fitBounds(bounds);
      }
    },
    async getCities() {
      await axios({
        method: "GET",
        url: API_BASE.city,
      })
        .then((e) => {
          this.cities = e.data;
          this.allCities = e.data;
          this.activeCity = this.cities[0].slug;
          this.addLayer(this.cities[0]);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getSurveys() {
      await axios({
        method: "GET",
        params: {
          quarter: this.currentQuarter,
        },
        url: API_BASE.survey,
      })
        .then((e) => {
          console.log(123123123);
          this.surveys = e.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
}
/* eslint-enable no-undef */
</script>