<template>
  <div class="inline-block">
    <div 
      :class="{
        active: isActive,
      }"
      class="logo flex flex-row items-center relative">
      <span class="margin-r-10">
        <img
          style="width: 40px; height: auto"
          src="/assets/images/logo.png"
          alt=""
        />
      </span>

      <div class="flex items-center gap-2 justify-center">
        <div class="flex flex-col">
          <span class="ml-1 f-18 font-semibold transition-all duration-200 ease-nav-brand"
          >
            Suraqjauap
          </span>
          <span class="ml-1 f-14 font-semibold transition-all duration-200 ease-nav-brand"
          >
            ({{ getCurrentQuarter }})
          </span>
        </div>
        <img 
          @click="() => {
            isActive = !isActive;
          }"
          :class="{
            active: isActive,
          }"
          style="width: 20px; height: auto;"
          class="ml-2 pointer header-icon"
          src="/assets/images/icons/drop-down-arrow.png" alt="">
      </div>

      <div 
        v-if="isActive"
        class="dropdown-list pointer">
        <div  
          v-for="(el, ind) in quarters" :key="ind"
          :class="{
            'font-bold': currentQuarter == ind + 2,
          }"
          @click="() => {
          setQuarter(ind+2);  
        }" >{{ el }}</div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      quarters: [
        '2 квартал',
        '3 квартал',
      ],
      currentQuarter: null,
    };
  },
  computed: {
    getCurrentQuarter() {
      return this.quarters[this.currentQuarter-2 || 0]; 
    },
  },
  mounted() { 
    this.currentQuarter = parseInt(localStorage.getItem('quarter')) || 2;
  },
  methods: {
    setQuarter(quarter) {
      this.currentQuarter = quarter;
      localStorage.setItem('quarter', quarter,);
      this.isActive = false;
      this.changeQuarter(quarter); 
    },
  },
  props: {
    changeQuarter: {
      default: () => {},
    },
  },
};
</script>