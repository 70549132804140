/* eslint-disable no-undef */

<template>
  <div>
    <Auth v-if="!isLogined" />
    <router-view v-else></router-view>
  </div>
</template>

<script>

import Auth from '@/layouts/Auth.vue';

export default {
  components: {
    Auth,
  },
  data() {
    return {
      isLogined: Boolean(localStorage.getItem('is_logined')) || false,
    };
  },
  computed: {
  }, 
  mounted() {
  }, 
  methods: {
    login() {
      this.isLogined = true;
      localStorage.setItem('is_logined', true, );
    },
  },
  provide() {
    return {
    };
  },
};
</script>

<style></style>